const ombudsmanTypes = {
  doubt: 'Dúvida',
  compliment: 'Elogio',
  complaint: 'Reclamação',
  suggestion: 'Sugestão',
};

export const ombudsmanTypesList = Object.entries(ombudsmanTypes).map(([key, value]) => ({ value: key, label: value }));

export default ombudsmanTypes;
