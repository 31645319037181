import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import BackgroundImageArea from '../../components/BackgroundImageArea';
import CredentialArea from '../../components/CredentialArea/CredentialArea';
import OmbudsmanForm from '../../components/OmbudsmanForm';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import { Container } from '../../components/ui/Wrapper';
import invoicesService from '../../services/invoicesService';
import ContactFormArea from '../contact-form-area';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';

const OmbudsmanPage = ({ location, pageContext }) => {
  const { ombudsmanJson } = useStaticQuery(graphql`
    query {
      ombudsmanJson {
        description
        title
      }
    }
  `);

  const { title, description } = ombudsmanJson;
  const [student, setStudent] = useState();

  const sortByClassName = (a, b) => a.label.localeCompare(b.label);

  const mapContractValue = item => ({
    enroll_code: item.enroll_code,
    class_name: item.class_name,
    parent_name: item.parent_name,
    label: `${item.class_name} (${item.enroll_code})`,
  });

  const handleGetEnrollments = (enrollments = []) => {
    const [first] = enrollments;

    if (!first) {
      toast.error(
        'Não encontramos nenhum contrato com os dados informados. Verifique se os mesmos estão corretos e tente novamente'
      );

      return;
    }

    const data = {
      id: first.id,
      name: first.name,
      contracts: enrollments.map(mapContractValue).sort(sortByClassName),
    };

    setStudent(data);
  };

  return (
    <>
      <SEO
        title="Ouvidoria - Curso Preparatório Garra - Bacacher e Portão, Curitiba, PR"
        description="Ouvidoria- Melhor Cursinho Preparatório de Curitiba, Pré-vestibular, Pré-Militar"
        location={location}
      />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title="Ouvidoria" />
      {student ? (
        <ContactFormArea form={<OmbudsmanForm student={student} />} withReCaptcha />
      ) : (
        <BackgroundImageArea fluid={{}}>
          <Container>
            <CredentialArea
              name="ombudsman_form"
              title={title}
              description={description}
              onFinishSubmission={handleGetEnrollments}
              handleFetchData={invoicesService.enrollmentsDetails}
            />
          </Container>
        </BackgroundImageArea>
      )}

      <Footer />
    </>
  );
};

OmbudsmanPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default OmbudsmanPage;
