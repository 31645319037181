import api, { chargeApi } from './api';

const BASE_URL = '/invoices/v2';

const enrollments = (socialNumber, birthday, reCaptchaToken) =>
  chargeApi.get(`${BASE_URL}/enrollments`, {
    params: { social_number: socialNumber, birthday },
    headers: { 'g-recaptcha': reCaptchaToken },
  });

const enrollmentsDetails = (socialNumber, birthday, reCaptchaToken) =>
  api.get(`/enrollments/details`, {
    params: { social_number: socialNumber, birthday },
    headers: { 'g-recaptcha': reCaptchaToken },
  });

export default {
  enrollments,
  enrollmentsDetails,
};
