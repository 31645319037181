import BackgroundImage from 'gatsby-background-image';
import styled, { css } from 'styled-components';
import { device } from '../../theme';

export default styled(BackgroundImage)`
  ${({ theme }) => css`
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: ${theme.colors.light};
    @media ${device.medium} {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  `}
`;
