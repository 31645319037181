import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import FieldError from '../FieldError';
import SubmitButton from '../SubmitButton/SubmitButton';
import Form, { FormGroup, Input } from '../ui/form';
import Label from '../ui/form/label';
import { Col, Row } from '../ui/Wrapper';
import { FormCard, FormContainer } from './styles';

const getErrorMessage = error => {
  switch (error) {
    case 'Contract not found':
      return 'Contrato não encontrado';
    default:
      return 'Algo deu errado. Tente novamente mais tarde';
  }
};

const validationSchema = Yup.object().shape({
  socialNumber: Yup.string().required('Campo obrigatório'),
  birthday: Yup.string()
    .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, 'Data inválida (dd/mm/aaaa)')
    .required('Campo obrigatório'),
});
const CredentialForm = ({ name, handleFetchData, onFinishSubmission }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchEnrollments = async (socialNumber, birthday) => {
    try {
      setIsLoading(true);
      const reCaptchaToken = await executeRecaptcha(name);
      const { data } = await handleFetchData(socialNumber, birthday, reCaptchaToken);
      onFinishSubmission(data);
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error?.response?.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async ({ socialNumber, birthday }) => {
    const formattedBirthday = format(parse(birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    fetchEnrollments(socialNumber, formattedBirthday);
  };

  const { handleSubmit, getFieldProps, errors, touched, submitCount } = useFormik({
    initialValues: {
      socialNumber: '',
      birthday: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <FormCard>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="socialNumber" description="Responsável" required>
                  CPF
                </Label>
                <InputMask {...getFieldProps('socialNumber')} mask="999.999.999-99" maskChar={null}>
                  {inputProps => (
                    <Input
                      {...inputProps}
                      type="text"
                      name="socialNumber"
                      id="socialNumber"
                      placeholder="Informe o CPF"
                    />
                  )}
                </InputMask>
                <FieldError name="socialNumber" errors={errors} touched={touched} submitCount={submitCount} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="birthday" description="Aluno" required>
                  Data de Nascimento
                </Label>
                <InputMask {...getFieldProps('birthday')} mask="99/99/9999" maskChar={null}>
                  {inputProps => (
                    <Input
                      {...inputProps}
                      type="text"
                      name="birthday"
                      id="birthday"
                      placeholder="Informe a data de nascimento"
                    />
                  )}
                </InputMask>
                <FieldError name="birthday" errors={errors} touched={touched} submitCount={submitCount} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <SubmitButton isLoading={isLoading} mt="24px" fullwidth />
            </Col>
          </Row>
        </Form>
      </FormContainer>
    </FormCard>
  );
};

CredentialForm.propTypes = {
  name: PropTypes.string.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  onFinishSubmission: PropTypes.func.isRequired,
};

export default CredentialForm;
