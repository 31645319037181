/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import contactService from '../../services/contactService';
import { ombudsmanTypesList } from '../../utils/constants/ombudsmanTypes';
import FieldError from '../FieldError';
import SubmitButton from '../SubmitButton/SubmitButton';
import Form, { FormGroup, Input, Select, Textarea } from '../ui/form';
import Label from '../ui/form/label/label';
import Heading from '../ui/Heading';
import { Col, Row } from '../ui/Wrapper';

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .matches(/doubt|compliment|suggestion|complaint/g)
    .required('Campo obrigatório'),
  enroll_code: Yup.string().required('Campo obrigatório'),
  description: Yup.string()
    .test('len', 'A mensagem deve conter no máximo 1028 caracteres', (val = '') => val.length <= 1028)
    .required('Campo obrigatório'),
});

const handleCloseFeedback = () => {
  window.location.reload();
};

const OmbudsmanForm = ({ student }) => {
  const [contract, setContract] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { FeedbackModal, openModal: openFeedbackModal } = useFeedbackModal({ onClose: handleCloseFeedback });

  const { handleSubmit, getFieldProps, errors, touched, submitCount, setFieldValue } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: async data => {
      setIsLoading(true);
      try {
        const reCaptchaToken = await executeRecaptcha('contact_form');

        await contactService.sendOmbudsmanMessage(data, reCaptchaToken);

        openFeedbackModal();
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Aconteceu um erro inesperado. Tente novamente mais tarde');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleChangeClass = ({ target: { value } }) => {
    setFieldValue('enroll_code', value);
    setContract(student.contracts.find(({ enroll_code }) => enroll_code === value));
  };

  return (
    <>
      <FeedbackModal title="Mensagem enviada">
        <p>Sua mensagem foi enviada com sucesso. Em breve entraremos em contato</p>
      </FeedbackModal>
      <Form onSubmit={handleSubmit}>
        <Heading as="h6" mb="30px" fontweight={500} letterspacing="2px" texttransform="uppercase" color="info">
          Ouvidoria
        </Heading>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" description="Aluno">
                Nome
              </Label>
              <Input value={student.name} type="text" name="name" id="name" disabled />
            </FormGroup>
          </Col>
        </Row>
        {contract?.parent_name ? (
          <Row>
            <Col lg={12}>
              <FormGroup mb="20px">
                <Label name="parent_name" description="Responsável">
                  Nome
                </Label>
                <Input value={contract.parent_name} type="text" name="parent_name" id="parent_name" disabled />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="enroll_code" required>
                Turma
              </Label>
              <Select
                {...getFieldProps('enroll_code')}
                name="enroll_code"
                id="enroll_code"
                onChange={handleChangeClass}
              >
                <option value="">Selecione uma opção</option>
                {student.contracts.map(({ enroll_code, label }) => (
                  <option value={enroll_code}>{label}</option>
                ))}
              </Select>
              <FieldError name="enroll_code" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="type" required>
                Assunto
              </Label>
              <Select {...getFieldProps('type')} name="type" id="type">
                <option value="">Selecione uma opção</option>
                {ombudsmanTypesList.map(({ value, label }) => (
                  <option value={value}>{label}</option>
                ))}
              </Select>
              <FieldError name="type" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="description" required>
                Mensagem
              </Label>
              <Textarea
                {...getFieldProps('description')}
                name="description"
                id="description"
                placeholder="Por favor, descreva o motivo de seu contato"
              />
              <FieldError name="description" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <SubmitButton isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

OmbudsmanForm.propTypes = {
  student: PropTypes.shape({
    name: PropTypes.string.isRequired,
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        enroll_code: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default OmbudsmanForm;
