import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import CredentialForm from '../CredentialForm';
import Heading from '../ui/Heading';
import Text from '../ui/Text';
import { Col, Row } from '../ui/Wrapper';
import { DescriptionContainer } from './styles';

const CredentialArea = ({ title, description, name, handleFetchData, onFinishSubmission }) => {
  return (
    <Row>
      <Col lg={6}>
        <DescriptionContainer>
          <Heading as="h3" position="relative" layout="quote" pl="34px">
            {title}
          </Heading>
          <Text mt="15px" ml="34px" fontSize="lg">
            {parse(description)}
          </Text>
        </DescriptionContainer>
      </Col>
      <Col lg={5} md={6} className="mx-auto">
        <CredentialForm name={name} handleFetchData={handleFetchData} onFinishSubmission={onFinishSubmission} />
      </Col>
    </Row>
  );
};

CredentialArea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  onFinishSubmission: PropTypes.func.isRequired,
};

export default CredentialArea;
