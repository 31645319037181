import styled, { css } from 'styled-components';

export const FormCard = styled.div`
  ${({ theme }) => css`
    max-width: 400px;
    margin-left: auto;
    background: ${theme.colors.white};
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    padding: 42px 30px 51px;
    border-radius: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const FormContainer = styled.div`
  text-align: left;
`;
